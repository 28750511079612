@tailwind base;
@tailwind components;
@tailwind utilities;
.glitch {
  text-decoration: none;
  white-space: nowrap;
}
.glitch:before, .glitch:after {
  display: block;
  content: attr(data-glitch);
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.8;
}
.glitch:after {
  color: #f0f;
  z-index: -2;
}
.glitch:before {
  color: #0ff;
  z-index: -1;
}
.glitch:hover:before {
  animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 5;
}
.glitch:hover:after {
  animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both 5;
}

@keyframes glitch {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-5px, 5px);
  }
  40% {
    transform: translate(-5px, -5px);
  }
  60% {
    transform: translate(5px, 5px);
  }
  80% {
    transform: translate(5px, -5px);
  }
  to {
    transform: translate(0);
  }
}
/*write blinking cursor animation */
.blink::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 0.66em;
  height: 1em;
  vertical-align: top;
  white-space: nowrap;
  margin-left: 1ch;
  background-color: currentColor;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.mySlider {
  width: 200px;
}

