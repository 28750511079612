

.glitch {

	text-decoration: none;
	white-space: nowrap;
	
	&:before, &:after {
		display: block;
		content: attr(data-glitch);
		text-transform: uppercase;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: .8;
	} &:after {
		color: #f0f;
		z-index: -2;
	} &:before {
		color: #0ff;
		z-index: -1;
	}
	&:hover {
		&:before {
			animation: glitch .3s cubic-bezier(.25, .46, .45, .94) both 5
		}
		&:after {
			animation: glitch .3s cubic-bezier(.25, .46, .45, .94) reverse both 5
		}
	}
}



@keyframes glitch {
	0% {
		transform: translate(0)
	}
	20% {
		transform: translate(-5px, 5px)
	}
	40% {
		transform: translate(-5px, -5px)
	}
	60% {
		transform: translate(5px, 5px)
	}
	80% {
		transform: translate(5px, -5px)
	}
	to {
		transform: translate(0)
	}
}




/*write blinking cursor animation */
.blink::after {
	content: '';
	position: absolute;
	display: inline-block;
	width: 0.66em;
	height: 1em;
	vertical-align: top;
	white-space: nowrap;
	margin-left: 1ch;
	background-color: currentColor;

	animation: blink 1s infinite;
}
@keyframes blink {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

